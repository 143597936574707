import React from 'react';
import cn from 'classnames';
import styles from './generateItem.module.css';
import { Par } from '../UI';

export const GenerateItem = ({
	first = false,
	loadWallet = true,
	delay = 10,
	title,
}) => {
	const [showID, setShowID] = React.useState(null);
	const [show, setShow] = React.useState(false);
	const [load, setLoad] = React.useState(true);
	const [loadID, setLoadID] = React.useState(null);

	React.useEffect(() => {
		if (!loadWallet) {
			setShowID(
				setTimeout(() => {
					setShow(true);
				}, delay)
			);
		}

		return () => clearTimeout(showID);
	}, [loadWallet, showID, delay]);

	React.useEffect(() => {
		if (!loadWallet && !first) {
			setLoadID(
				setTimeout(() => {
					setLoad(false);
				}, delay + 3000)
			);
		} else if (!loadWallet && first) {
			setLoadID(
				setTimeout(() => {
					setLoad(false);
				}, 1000)
			);
		}
		return () => clearTimeout(loadID);
	}, [loadWallet, loadID, delay, first]);

	return (
		<div
			className={cn(styles.item, {
				[styles.showItem]: show || first,
			})}
		>
			<div>
				{!load ? (
					<svg
						width='70'
						height='19'
						viewBox='0 0 70 19'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.16188 0.893039C8.60854 2.10127 8.35702 3.41456 8.25641 5.41076C8.20611 6.72405 8.1055 7.14431 7.95459 7.3019C7.75338 7.35444 7.45156 7.3019 5.69094 6.56646C4.53396 6.04114 1.56606 5.1481 1.46546 5.25317C1.41515 5.25317 2.01879 5.56836 2.82365 5.93608C3.5782 6.3038 4.88609 6.98671 5.64064 7.4595C6.64496 8.06127 7.37445 8.58426 7.90143 9.17582C7.22787 9.54264 6.56371 9.67249 5.41422 9.85449C3.57799 10.1228 1.94912 10.0874 0.789785 9.6956C0.368168 9.54854 -0.000459696 9.45131 4.30293e-07 9.50161C0.000461423 9.55191 0.42254 9.74927 0.897147 9.94615C2.11039 10.4884 3.42593 10.7279 5.42297 10.8102C6.73667 10.8485 7.15783 10.9452 7.3168 11.0947C7.37117 11.2954 7.3214 11.5977 6.60211 13.365C6.08741 14.5267 5.22158 17.5027 5.32756 17.6023C5.32802 17.6526 5.63767 17.0461 5.99801 16.2379C6.3588 15.4801 7.02971 14.166 7.49557 13.4071C8.00867 12.5327 8.46459 11.8649 8.95856 11.3559C9.1544 12.272 9.21218 13.4226 9.21218 14.9715C9.21218 17.2304 9.16188 17.5456 8.91036 18.2285C8.55824 19.1215 8.60854 19.2791 9.01097 18.4911C9.76552 16.9677 10.0673 15.4968 10.1176 13.1329C10.1176 11.6095 10.1679 11.2943 10.3692 11.0842C10.6207 10.8215 10.671 10.8215 11.0231 11.0317C11.5764 11.2943 13.991 13.2905 15.0474 14.3411L15.9025 15.1817L15.0474 14.026C14.5443 13.3956 13.2364 11.8722 12.1298 10.6639C11.9116 10.4313 11.7119 10.2172 11.5289 10.0183C12.4153 9.84143 13.5188 9.78028 14.9746 9.76695C17.2334 9.74627 17.549 9.79368 18.2342 10.0389C19.1304 10.3829 19.2875 10.3311 18.4959 9.93593C16.9656 9.19536 15.4921 8.90702 13.1278 8.87836C11.6044 8.89231 11.2888 8.84489 11.0768 8.64561C10.8119 8.39651 10.8114 8.34621 11.0183 7.99217C11.2759 7.43645 13.2499 5.00372 14.2908 3.93777L15.1235 3.07496L13.9756 3.94066C13.3499 4.44944 11.8385 5.77122 10.6405 6.88891C10.3176 7.19735 10.0306 7.46922 9.77075 7.70961C9.48327 7.09538 9.36723 6.4517 9.21218 5.41076C8.96066 3.57215 9.01097 1.94367 9.41339 0.787975C9.5643 0.367722 9.66491 0 9.6146 0C9.5643 0 9.36309 0.420254 9.16188 0.893039Z'
							fill='var(--success)'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M34.1843 0.893039C33.631 2.10127 33.3795 3.41456 33.2789 5.41076C33.2286 6.72405 33.128 7.14431 32.9771 7.3019C32.7758 7.35444 32.474 7.3019 30.7134 6.56646C29.5564 6.04114 26.5885 5.1481 26.4879 5.25317C26.4376 5.25317 27.0413 5.56836 27.8461 5.93608C28.6007 6.3038 29.9086 6.98671 30.6631 7.4595C31.6674 8.06127 32.3969 8.58426 32.9239 9.17582C32.2503 9.54264 31.5862 9.67249 30.4367 9.85449C28.6005 10.1228 26.9716 10.0874 25.8122 9.6956C25.3906 9.54854 25.022 9.45131 25.0225 9.50161C25.0229 9.55191 25.445 9.74927 25.9196 9.94615C27.1329 10.4884 28.4484 10.7279 30.4454 10.8102C31.7591 10.8485 32.1803 10.9452 32.3393 11.0947C32.3936 11.2954 32.3439 11.5977 31.6246 13.365C31.1099 14.5267 30.244 17.5027 30.35 17.6023C30.3505 17.6526 30.6601 17.0461 31.0205 16.2379C31.3813 15.4801 32.0522 14.166 32.518 13.4071C33.0311 12.5327 33.4871 11.8649 33.981 11.3559C34.1769 12.272 34.2346 13.4226 34.2346 14.9715C34.2346 17.2304 34.1843 17.5456 33.9328 18.2285C33.5807 19.1215 33.631 19.2791 34.0334 18.4911C34.788 16.9677 35.0898 15.4968 35.1401 13.1329C35.1401 11.6095 35.1904 11.2943 35.3916 11.0842C35.6431 10.8215 35.6934 10.8215 36.0456 11.0317C36.5989 11.2943 39.0135 13.2905 40.0698 14.3411L40.925 15.1817L40.0698 14.026C39.5668 13.3956 38.2589 11.8722 37.1522 10.6639C36.9341 10.4313 36.7344 10.2172 36.5513 10.0183C37.4378 9.84143 38.5413 9.78028 39.997 9.76695C42.2558 9.74627 42.5714 9.79368 43.2566 10.0389C44.1529 10.3829 44.31 10.3311 43.5184 9.93593C41.9881 9.19536 40.5145 8.90702 38.1502 8.87836C36.6269 8.89231 36.3112 8.84489 36.0993 8.64561C35.8343 8.39651 35.8339 8.34621 36.0408 7.99217C36.2983 7.43645 38.2724 5.00372 39.3133 3.93777L40.1459 3.07496L38.9981 3.94066C38.3723 4.44944 36.861 5.77122 35.6629 6.88891C35.3401 7.19735 35.0531 7.46922 34.7932 7.70961C34.5057 7.09538 34.3897 6.4517 34.2346 5.41076C33.9831 3.57215 34.0334 1.94367 34.4359 0.787975C34.5868 0.367722 34.6874 0 34.6371 0C34.5868 0 34.3856 0.420254 34.1843 0.893039Z'
							fill='var(--success)'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M59.2644 0.893039C58.7111 2.10127 58.4596 3.41456 58.359 5.41076C58.3087 6.72405 58.208 7.14431 58.0571 7.3019C57.8559 7.35444 57.5541 7.3019 55.7935 6.56646C54.6365 6.04114 51.6686 5.1481 51.568 5.25317C51.5177 5.25317 52.1213 5.56836 52.9262 5.93608C53.6807 6.3038 54.9886 6.98671 55.7432 7.4595C56.7475 8.06127 57.477 8.58426 58.004 9.17582C57.3304 9.54264 56.6662 9.67249 55.5168 9.85449C53.6805 10.1228 52.0517 10.0874 50.8923 9.6956C50.4707 9.54854 50.1021 9.45131 50.1025 9.50161C50.103 9.55191 50.5251 9.74927 50.9997 9.94615C52.2129 10.4884 53.5285 10.7279 55.5255 10.8102C56.8392 10.8485 57.2604 10.9452 57.4193 11.0947C57.4737 11.2954 57.4239 11.5977 56.7046 13.365C56.1899 14.5267 55.3241 17.5027 55.4301 17.6023C55.4306 17.6526 55.7402 17.0461 56.1005 16.2379C56.4613 15.4801 57.1323 14.166 57.5981 13.4071C58.1112 12.5327 58.5671 11.8649 59.0611 11.3559C59.2569 12.272 59.3147 13.4226 59.3147 14.9715C59.3147 17.2304 59.2644 17.5456 59.0129 18.2285C58.6608 19.1215 58.7111 19.2791 59.1135 18.4911C59.8681 16.9677 60.1699 15.4968 60.2202 13.1329C60.2202 11.6095 60.2705 11.2943 60.4717 11.0842C60.7232 10.8215 60.7735 10.8215 61.1256 11.0317C61.679 11.2943 64.0935 13.2905 65.1499 14.3411L66.0051 15.1817L65.1499 14.026C64.6469 13.3956 63.339 11.8722 62.2323 10.6639C62.0141 10.4313 61.8144 10.2172 61.6314 10.0183C62.5179 9.84143 63.6214 9.78028 65.0771 9.76695C67.3359 9.74627 67.6515 9.79368 68.3367 10.0389C69.2329 10.3829 69.3901 10.3311 68.5984 9.93593C67.0682 9.19536 65.5946 8.90702 63.2303 8.87836C61.7069 8.89231 61.3913 8.84489 61.1793 8.64561C60.9144 8.39651 60.9139 8.34621 61.1208 7.99217C61.3784 7.43645 63.3524 5.00372 64.3933 3.93777L65.226 3.07496L64.0782 3.94066C63.4524 4.44944 61.941 5.77122 60.743 6.88891C60.4202 7.19734 60.1332 7.46922 59.8733 7.7096C59.5858 7.09538 59.4698 6.4517 59.3147 5.41076C59.0632 3.57215 59.1135 1.94367 59.5159 0.787975C59.6668 0.367722 59.7674 0 59.7171 0C59.6668 0 59.4656 0.420254 59.2644 0.893039Z'
							fill='var(--success)'
						/>
					</svg>
				) : (
					<svg
						width='70'
						height='19'
						viewBox='0 0 70 19'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M9.16188 0.893039C8.60854 2.10127 8.35702 3.41456 8.25641 5.41076C8.20611 6.72405 8.1055 7.14431 7.95459 7.3019C7.75338 7.35444 7.45156 7.3019 5.69094 6.56646C4.53396 6.04114 1.56606 5.1481 1.46546 5.25317C1.41515 5.25317 2.01879 5.56836 2.82365 5.93608C3.5782 6.3038 4.88609 6.98671 5.64064 7.4595C6.64496 8.06127 7.37445 8.58426 7.90143 9.17582C7.22787 9.54264 6.56371 9.67249 5.41422 9.85449C3.57799 10.1228 1.94912 10.0874 0.789785 9.6956C0.368168 9.54854 -0.000459696 9.45131 4.30293e-07 9.50161C0.000461423 9.55191 0.42254 9.74927 0.897147 9.94615C2.11039 10.4884 3.42593 10.7279 5.42297 10.8102C6.73667 10.8485 7.15783 10.9452 7.3168 11.0947C7.37117 11.2954 7.3214 11.5977 6.60211 13.365C6.08741 14.5267 5.22158 17.5027 5.32756 17.6023C5.32802 17.6526 5.63767 17.0461 5.99801 16.2379C6.3588 15.4801 7.02971 14.166 7.49557 13.4071C8.00867 12.5327 8.46459 11.8649 8.95856 11.3559C9.1544 12.272 9.21218 13.4226 9.21218 14.9715C9.21218 17.2304 9.16188 17.5456 8.91036 18.2285C8.55824 19.1215 8.60854 19.2791 9.01097 18.4911C9.76552 16.9677 10.0673 15.4968 10.1176 13.1329C10.1176 11.6095 10.1679 11.2943 10.3692 11.0842C10.6207 10.8215 10.671 10.8215 11.0231 11.0317C11.5764 11.2943 13.991 13.2905 15.0474 14.3411L15.9025 15.1817L15.0474 14.026C14.5443 13.3956 13.2364 11.8722 12.1298 10.6639C11.9116 10.4313 11.7119 10.2172 11.5289 10.0183C12.4153 9.84143 13.5188 9.78028 14.9746 9.76695C17.2334 9.74627 17.549 9.79368 18.2342 10.0389C19.1304 10.3829 19.2875 10.3311 18.4959 9.93593C16.9656 9.19536 15.4921 8.90702 13.1278 8.87836C11.6044 8.89231 11.2888 8.84489 11.0768 8.64561C10.8119 8.39651 10.8114 8.34621 11.0183 7.99217C11.2759 7.43645 13.2499 5.00372 14.2908 3.93777L15.1235 3.07496L13.9756 3.94066C13.3499 4.44944 11.8385 5.77122 10.6405 6.88891C10.3176 7.19735 10.0306 7.46922 9.77075 7.70961C9.48327 7.09538 9.36723 6.4517 9.21218 5.41076C8.96066 3.57215 9.01097 1.94367 9.41339 0.787975C9.5643 0.367722 9.66491 0 9.6146 0C9.5643 0 9.36309 0.420254 9.16188 0.893039Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M34.1843 0.893039C33.631 2.10127 33.3795 3.41456 33.2789 5.41076C33.2286 6.72405 33.128 7.14431 32.9771 7.3019C32.7758 7.35444 32.474 7.3019 30.7134 6.56646C29.5564 6.04114 26.5885 5.1481 26.4879 5.25317C26.4376 5.25317 27.0413 5.56836 27.8461 5.93608C28.6007 6.3038 29.9086 6.98671 30.6631 7.4595C31.6674 8.06127 32.3969 8.58426 32.9239 9.17582C32.2503 9.54264 31.5862 9.67249 30.4367 9.85449C28.6005 10.1228 26.9716 10.0874 25.8122 9.6956C25.3906 9.54854 25.022 9.45131 25.0225 9.50161C25.0229 9.55191 25.445 9.74927 25.9196 9.94615C27.1329 10.4884 28.4484 10.7279 30.4454 10.8102C31.7591 10.8485 32.1803 10.9452 32.3393 11.0947C32.3936 11.2954 32.3439 11.5977 31.6246 13.365C31.1099 14.5267 30.244 17.5027 30.35 17.6023C30.3505 17.6526 30.6601 17.0461 31.0205 16.2379C31.3813 15.4801 32.0522 14.166 32.518 13.4071C33.0311 12.5327 33.4871 11.8649 33.981 11.3559C34.1769 12.272 34.2346 13.4226 34.2346 14.9715C34.2346 17.2304 34.1843 17.5456 33.9328 18.2285C33.5807 19.1215 33.631 19.2791 34.0334 18.4911C34.788 16.9677 35.0898 15.4968 35.1401 13.1329C35.1401 11.6095 35.1904 11.2943 35.3916 11.0842C35.6431 10.8215 35.6934 10.8215 36.0456 11.0317C36.5989 11.2943 39.0135 13.2905 40.0698 14.3411L40.925 15.1817L40.0698 14.026C39.5668 13.3956 38.2589 11.8722 37.1522 10.6639C36.9341 10.4313 36.7344 10.2172 36.5513 10.0183C37.4378 9.84143 38.5413 9.78028 39.997 9.76695C42.2558 9.74627 42.5714 9.79368 43.2566 10.0389C44.1529 10.3829 44.31 10.3311 43.5184 9.93593C41.9881 9.19536 40.5145 8.90702 38.1502 8.87836C36.6269 8.89231 36.3112 8.84489 36.0993 8.64561C35.8343 8.39651 35.8339 8.34621 36.0408 7.99217C36.2983 7.43645 38.2724 5.00372 39.3133 3.93777L40.1459 3.07496L38.9981 3.94066C38.3723 4.44944 36.861 5.77122 35.6629 6.88891C35.3401 7.19735 35.0531 7.46922 34.7932 7.70961C34.5057 7.09538 34.3897 6.4517 34.2346 5.41076C33.9831 3.57215 34.0334 1.94367 34.4359 0.787975C34.5868 0.367722 34.6874 0 34.6371 0C34.5868 0 34.3856 0.420254 34.1843 0.893039Z'
							fill='white'
						/>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M59.2644 0.893039C58.7111 2.10127 58.4596 3.41456 58.359 5.41076C58.3087 6.72405 58.208 7.14431 58.0571 7.3019C57.8559 7.35444 57.5541 7.3019 55.7935 6.56646C54.6365 6.04114 51.6686 5.1481 51.568 5.25317C51.5177 5.25317 52.1213 5.56836 52.9262 5.93608C53.6807 6.3038 54.9886 6.98671 55.7432 7.4595C56.7475 8.06127 57.477 8.58426 58.004 9.17582C57.3304 9.54264 56.6662 9.67249 55.5168 9.85449C53.6805 10.1228 52.0517 10.0874 50.8923 9.6956C50.4707 9.54854 50.1021 9.45131 50.1025 9.50161C50.103 9.55191 50.5251 9.74927 50.9997 9.94615C52.2129 10.4884 53.5285 10.7279 55.5255 10.8102C56.8392 10.8485 57.2604 10.9452 57.4193 11.0947C57.4737 11.2954 57.4239 11.5977 56.7046 13.365C56.1899 14.5267 55.3241 17.5027 55.4301 17.6023C55.4306 17.6526 55.7402 17.0461 56.1005 16.2379C56.4613 15.4801 57.1323 14.166 57.5981 13.4071C58.1112 12.5327 58.5671 11.8649 59.0611 11.3559C59.2569 12.272 59.3147 13.4226 59.3147 14.9715C59.3147 17.2304 59.2644 17.5456 59.0129 18.2285C58.6608 19.1215 58.7111 19.2791 59.1135 18.4911C59.8681 16.9677 60.1699 15.4968 60.2202 13.1329C60.2202 11.6095 60.2705 11.2943 60.4717 11.0842C60.7232 10.8215 60.7735 10.8215 61.1256 11.0317C61.679 11.2943 64.0935 13.2905 65.1499 14.3411L66.0051 15.1817L65.1499 14.026C64.6469 13.3956 63.339 11.8722 62.2323 10.6639C62.0141 10.4313 61.8144 10.2172 61.6314 10.0183C62.5179 9.84143 63.6214 9.78028 65.0771 9.76695C67.3359 9.74627 67.6515 9.79368 68.3367 10.0389C69.2329 10.3829 69.3901 10.3311 68.5984 9.93593C67.0682 9.19536 65.5946 8.90702 63.2303 8.87836C61.7069 8.89231 61.3913 8.84489 61.1793 8.64561C60.9144 8.39651 60.9139 8.34621 61.1208 7.99217C61.3784 7.43645 63.3524 5.00372 64.3933 3.93777L65.226 3.07496L64.0782 3.94066C63.4524 4.44944 61.941 5.77122 60.743 6.88891C60.4202 7.19734 60.1332 7.46922 59.8733 7.7096C59.5858 7.09538 59.4698 6.4517 59.3147 5.41076C59.0632 3.57215 59.1135 1.94367 59.5159 0.787975C59.6668 0.367722 59.7674 0 59.7171 0C59.6668 0 59.4656 0.420254 59.2644 0.893039Z'
							fill='white'
						/>
					</svg>
				)}
			</div>
			<Par fw={500} size='m' center color={!load ? 'green' : 'white'}>
				{title}
			</Par>
		</div>
	);
};
