import React from 'react';

export function SvgMenu(props) {
	const { type, fill } = props;

	switch (type) {
		case 'wallet':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M21.1 8.004C21.045 8 20.984 8 20.92 8h-2.525c-2.068 0-3.837 1.628-3.837 3.75s1.77 3.75 3.837 3.75h2.525c.064 0 .125 0 .182-.004a1.755 1.755 0 001.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 00-1.645-1.628zm-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1z'
						fill='#fff'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M20.918 17a.22.22 0 01.221.278c-.2.712-.519 1.32-1.03 1.83-.749.75-1.698 1.081-2.87 1.239-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87C2 15.099 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.511.512.83 1.119 1.03 1.831a.22.22 0 01-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25h2.524zM5.75 7a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z'
						fill='#fff'
					/>
				</svg>
			);
		case 'import':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464zM12 7.25a.75.75 0 01.75.75v6.19l1.72-1.72a.75.75 0 111.06 1.06l-3 3a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06l1.72 1.72V8a.75.75 0 01.75-.75z'
						fill='#fff'
					/>
				</svg>
			);
		case 'backup':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm3.935-16.408a.75.75 0 01.467.694v2.715a.75.75 0 01-.75.75H13a.75.75 0 01-.537-1.274l.762-.78a4.17 4.17 0 00-4.224 1.089c-1.668 1.707-1.668 4.483 0 6.19a4.169 4.169 0 005.998 0 4.394 4.394 0 001.208-2.472c.058-.418.39-.77.812-.77.406 0 .742.325.703.729a5.897 5.897 0 01-1.65 3.562 5.669 5.669 0 01-8.144 0c-2.237-2.29-2.237-5.997 0-8.287a5.666 5.666 0 016.437-1.208l.75-.768a.75.75 0 01.82-.17z'
						fill='#fff'
					/>
				</svg>
			);
		case 'security':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M5.25 10.055V8a6.75 6.75 0 0113.5 0v2.055c1.115.083 1.84.293 2.371.824C22 11.757 22 13.172 22 16c0 2.828 0 4.243-.879 5.121C20.243 22 18.828 22 16 22H8c-2.828 0-4.243 0-5.121-.879C2 20.243 2 18.828 2 16c0-2.828 0-4.243.879-5.121.53-.531 1.256-.741 2.371-.824zM6.75 8a5.25 5.25 0 0110.5 0v2.004C16.867 10 16.451 10 16 10H8c-.452 0-.867 0-1.25.004V8zM14 16a2 2 0 11-4 0 2 2 0 014 0z'
						fill='#fff'
					/>
				</svg>
			);
		case 'bell':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M8.352 20.242A4.63 4.63 0 0012 22a4.63 4.63 0 003.648-1.758 27.158 27.158 0 01-7.296 0zM18.75 9v.704c0 .845.24 1.671.692 2.374l1.108 1.723c1.011 1.574.239 3.713-1.52 4.21a25.794 25.794 0 01-14.06 0c-1.759-.497-2.531-2.636-1.52-4.21l1.108-1.723c.452-.703.693-1.529.693-2.374V9c0-3.866 3.022-7 6.749-7s6.75 3.134 6.75 7z'
						fill='#fff'
					/>
				</svg>
			);
		case 'rpc':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464zm10.024 2.982a.75.75 0 01.53.918l-2.588 9.66a.75.75 0 01-1.449-.389l2.588-9.659a.75.75 0 01.92-.53zM14.97 8.47a.75.75 0 011.06 0l.209.208c.635.635 1.165 1.165 1.529 1.642.384.504.654 1.036.654 1.68 0 .644-.27 1.176-.654 1.68-.364.477-.894 1.007-1.53 1.642l-.208.208a.75.75 0 11-1.06-1.06l.171-.172c.682-.682 1.139-1.14 1.434-1.528.283-.37.347-.586.347-.77 0-.184-.064-.4-.347-.77-.295-.387-.752-.846-1.434-1.528l-.171-.172a.75.75 0 010-1.06zm-7 0a.75.75 0 011.06 1.06l-.171.172c-.682.682-1.138 1.14-1.434 1.528-.283.37-.346.586-.346.77 0 .184.063.4.346.77.296.387.752.846 1.434 1.528l.172.172a.75.75 0 11-1.061 1.06l-.208-.208c-.636-.635-1.166-1.165-1.53-1.642-.384-.504-.653-1.036-.653-1.68 0-.644.27-1.176.653-1.68.364-.477.894-1.007 1.53-1.642l.208-.208z'
						fill='#fff'
					/>
				</svg>
			);
		case 'connect':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 8.035c-2.697 0-4.884 2.151-4.884 4.806a4.75 4.75 0 001.43 3.398.679.679 0 010 .97.706.706 0 01-.986 0 6.113 6.113 0 01-1.84-4.368c0-3.413 2.812-6.18 6.28-6.18 3.468 0 6.28 2.767 6.28 6.18a6.113 6.113 0 01-1.84 4.369.706.706 0 01-.987 0 .679.679 0 010-.971 4.75 4.75 0 001.43-3.398c0-2.655-2.186-4.806-4.883-4.806z'
						fill='#fff'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 4.373c-4.752 0-8.605 3.791-8.605 8.468 0 2.338.963 4.454 2.52 5.987a.679.679 0 010 .97.706.706 0 01-.986 0A9.734 9.734 0 012 12.842C2 7.406 6.477 3 12 3s10 4.406 10 9.84a9.734 9.734 0 01-2.929 6.959.706.706 0 01-.987 0 .679.679 0 010-.971 8.372 8.372 0 002.52-5.987c0-4.677-3.852-8.468-8.604-8.468z'
						fill='#fff'
					/>
					<path
						d='M10.31 17.344c.767-.876 1.151-1.314 1.625-1.342.043-.003.087-.003.13 0 .474.028.858.466 1.625 1.342 1.67 1.906 2.505 2.858 2.271 3.68-.02.071-.045.14-.074.206-.344.77-1.525.77-3.887.77s-3.543 0-3.887-.77a1.527 1.527 0 01-.074-.206c-.234-.822.6-1.774 2.27-3.68zM14.5 12.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z'
						fill='#fff'
					/>
				</svg>
			);
		case 'about':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M12 22c-4.714 0-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22zm0-4.25a.75.75 0 00.75-.75v-6a.75.75 0 00-1.5 0v6c0 .414.336.75.75.75zM12 7a1 1 0 110 2 1 1 0 010-2z'
						fill='#fff'
					/>
				</svg>
			);
		case 'success':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M22 11.5a9.5 9.5 0 11-19 0 9.5 9.5 0 0119 0z'
						fill='#02FEA4'
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M16.757 8.24c.316.328.325.87.02 1.21l-4.651 5.2a1.008 1.008 0 01-1.55-.03L8.203 11.77a.904.904 0 01.06-1.21.755.755 0 011.122.067l1.993 2.395 4.256-4.76a.754.754 0 011.123-.021z'
						fill='#090D15'
					/>
				</svg>
			);
		case 'error':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M7.843 3.802C9.872 2.601 10.886 2 12 2c1.114 0 2.128.6 4.157 1.802l.686.406c2.029 1.202 3.043 1.803 3.6 2.792.557.99.557 2.19.557 4.594v.812c0 2.403 0 3.605-.557 4.594-.557.99-1.571 1.59-3.6 2.791l-.686.407C14.128 21.399 13.114 22 12 22c-1.114 0-2.128-.6-4.157-1.802l-.686-.407c-2.029-1.2-3.043-1.802-3.6-2.791C3 16.01 3 14.81 3 12.406v-.812C3 9.19 3 7.989 3.557 7c.557-.99 1.571-1.59 3.6-2.792l.686-.406zM13 16a1 1 0 11-2 0 1 1 0 012 0zm-1-9.75a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V7a.75.75 0 01.75-.75z'
						fill='#E61C05'
					/>
				</svg>
			);
		case 'send':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M15.53 13.059l1.43-4.292c1.25-3.748 1.874-5.623.885-6.612-.99-.99-2.864-.365-6.612.885L6.942 4.47C3.916 5.48 2.403 5.983 1.973 6.722a2.264 2.264 0 000 2.276c.43.74 1.943 1.244 4.969 2.252a1.06 1.06 0 001.077-.242l4.588-4.546a.658.658 0 11.926.935L9.02 11.87c-.31.307-.407.776-.27 1.19 1.01 3.025 1.513 4.538 2.253 4.968a2.264 2.264 0 002.275 0c.74-.43 1.244-1.943 2.253-4.969z'
						fill='#fff'
					/>
				</svg>
			);
		case 'receive':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M2.887 2.887c-1.22 1.22-1.22 3.185-1.22 7.113 0 3.928 0 5.893 1.22 7.113 1.22 1.22 3.185 1.22 7.113 1.22 3.928 0 5.893 0 7.113-1.22 1.22-1.22 1.22-3.185 1.22-7.113 0-3.928 0-5.893-1.22-7.113-1.22-1.22-3.185-1.22-7.113-1.22-3.928 0-5.893 0-7.113 1.22zM10 6.042c.345 0 .625.28.625.625v5.157l1.433-1.433a.625.625 0 11.884.884l-2.5 2.5a.625.625 0 01-.884 0l-2.5-2.5a.625.625 0 01.884-.884l1.433 1.433V6.667c0-.346.28-.625.625-.625z'
						fill='#fff'
					/>
				</svg>
			);
		case 'buy':
			return (
				<svg
					width={20}
					height={20}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M4.645 3.91c-.694.576-.877 1.551-1.243 3.502l-.625 3.333c-.514 2.743-.772 4.115-.022 5.018.75.904 2.145.904 4.936.904h4.618c2.79 0 4.186 0 4.936-.904.75-.903.492-2.275-.022-5.018l-.625-3.333c-.366-1.95-.549-2.926-1.243-3.502-.694-.577-1.687-.577-3.671-.577H8.316c-1.984 0-2.977 0-3.671.577zm3.587 2.548a1.876 1.876 0 003.537 0 .625.625 0 111.178.417 3.126 3.126 0 01-5.894 0 .625.625 0 011.179-.417z'
						fill='#fff'
					/>
				</svg>
			);
		case 'home':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M13 15.4c0-2.074 0-3.111.659-3.756C14.318 11 15.379 11 17.5 11c2.121 0 3.182 0 3.841.644C22 12.29 22 13.326 22 15.4v2.2c0 2.074 0 3.111-.659 3.756-.659.644-1.72.644-3.841.644-2.121 0-3.182 0-3.841-.644C13 20.71 13 19.674 13 17.6v-2.2zM2 8.6c0 2.074 0 3.111.659 3.756C3.318 13 4.379 13 6.5 13c2.121 0 3.182 0 3.841-.644C11 11.71 11 10.674 11 8.6V6.4c0-2.074 0-3.111-.659-3.756C9.682 2 8.621 2 6.5 2c-2.121 0-3.182 0-3.841.644C2 3.29 2 4.326 2 6.4v2.2zM13 5.5c0-1.087 0-1.63.171-2.06a2.293 2.293 0 011.218-1.262C14.802 2 15.327 2 16.375 2h2.25c1.048 0 1.573 0 1.986.178.551.236.99.69 1.218 1.262.171.43.171.973.171 2.06 0 1.087 0 1.63-.171 2.06a2.293 2.293 0 01-1.218 1.262C20.198 9 19.673 9 18.625 9h-2.25c-1.048 0-1.573 0-1.986-.178a2.293 2.293 0 01-1.218-1.262C13 7.13 13 6.587 13 5.5zM2 18.5c0 1.087 0 1.63.171 2.06a2.293 2.293 0 001.218 1.262c.413.178.938.178 1.986.178h2.25c1.048 0 1.573 0 1.986-.178.551-.236.99-.69 1.218-1.262.171-.43.171-.973.171-2.06 0-1.087 0-1.63-.171-2.06a2.293 2.293 0 00-1.218-1.262C9.198 15 8.673 15 7.625 15h-2.25c-1.048 0-1.573 0-1.986.178-.551.236-.99.69-1.218 1.262C2 16.87 2 17.413 2 18.5z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'buy-crypto':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M21.1 8.004A2.954 2.954 0 0020.92 8h-2.525c-2.068 0-3.837 1.628-3.837 3.75 0 2.123 1.77 3.75 3.837 3.75h2.525c.064 0 .125 0 .182-.004a1.755 1.755 0 001.645-1.628c.004-.06.004-.125.004-.185V9.817c0-.06 0-.125-.004-.185a1.755 1.755 0 00-1.645-1.628zm-2.928 4.746c.532 0 .963-.448.963-1s-.431-1-.963-1c-.533 0-.964.448-.964 1s.431 1 .964 1z'
						fill={fill ? fill : 'var(--light)'}
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M20.918 17a.22.22 0 01.221.278c-.2.712-.519 1.32-1.03 1.83-.749.75-1.698 1.081-2.87 1.239-1.14.153-2.595.153-4.433.153h-2.112c-1.838 0-3.294 0-4.433-.153-1.172-.158-2.121-.49-2.87-1.238-.748-.749-1.08-1.698-1.238-2.87C2 15.1 2 13.644 2 11.806v-.112C2 9.856 2 8.4 2.153 7.26c.158-1.172.49-2.121 1.238-2.87.749-.748 1.698-1.08 2.87-1.238C7.401 3 8.856 3 10.694 3h2.112c1.838 0 3.294 0 4.433.153 1.172.158 2.121.49 2.87 1.238.511.512.83 1.119 1.03 1.831a.22.22 0 01-.221.278h-2.524c-2.837 0-5.337 2.24-5.337 5.25s2.5 5.25 5.337 5.25h2.524zM5.75 7a.75.75 0 000 1.5h4a.75.75 0 000-1.5h-4z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'swap':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M10 4h4c3.771 0 5.657 0 6.828 1.172.844.843 1.08 2.057 1.146 4.078H2.026c.066-2.021.302-3.235 1.146-4.078C4.343 4 6.229 4 10 4z'
						fill={fill ? fill : 'var(--light)'}
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M22 12.818l-.409-.409a2.25 2.25 0 00-3.182 0l-.801.801a2.251 2.251 0 00-4.358.79v1.764a2.25 2.25 0 00-1.341 3.827l.409.409H10c-3.771 0-5.657 0-6.828-1.172C2 17.657 2 15.771 2 12c0-.442 0-.858.002-1.25h19.996c.002.392.002.808.002 1.25v.818zM6 15.25a.75.75 0 000 1.5h4a.75.75 0 000-1.5H6z'
						fill={fill ? fill : 'var(--light)'}
					/>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M15.5 13.25a.75.75 0 01.75.75v4.19l.72-.72a.75.75 0 111.06 1.06l-2 2a.75.75 0 01-1.06 0l-2-2a.75.75 0 011.06-1.06l.72.72V14a.75.75 0 01.75-.75zm3.97.22a.75.75 0 011.06 0l2 2a.75.75 0 01-1.06 1.06l-.72-.72V20a.75.75 0 01-1.5 0v-4.19l-.72.72a.75.75 0 11-1.06-1.06l2-2z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'settings':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M14.279 2.152C13.909 2 13.439 2 12.5 2s-1.409 0-1.779.152a2.008 2.008 0 00-1.09 1.083c-.094.223-.13.484-.145.863a1.615 1.615 0 01-.796 1.353 1.64 1.64 0 01-1.579.008c-.338-.178-.583-.276-.825-.308a2.026 2.026 0 00-1.49.396c-.318.242-.553.646-1.022 1.453-.47.807-.704 1.21-.757 1.605-.07.526.074 1.058.4 1.479.148.192.357.353.68.555.477.297.783.803.783 1.361 0 .558-.306 1.064-.782 1.36-.324.203-.533.364-.682.556-.325.421-.469.953-.399 1.48.053.393.287.797.757 1.604.47.807.704 1.21 1.022 1.453.424.323.96.465 1.49.396.242-.032.487-.13.825-.308a1.64 1.64 0 011.58.008c.486.28.774.795.795 1.353.015.38.051.64.145.863.204.49.596.88 1.09 1.083.37.152.84.152 1.779.152s1.409 0 1.779-.152a2.007 2.007 0 001.09-1.083c.094-.223.13-.483.145-.863.02-.558.309-1.074.796-1.353a1.64 1.64 0 011.579-.008c.338.178.583.276.825.308.53.07 1.066-.073 1.49-.396.318-.242.553-.646 1.022-1.453.47-.807.704-1.21.757-1.605a1.99 1.99 0 00-.4-1.479c-.148-.192-.357-.353-.68-.555-.477-.297-.783-.803-.783-1.361 0-.558.306-1.064.782-1.36.324-.203.533-.364.682-.556a1.99 1.99 0 00.399-1.479c-.053-.394-.287-.798-.757-1.605-.47-.807-.704-1.21-1.022-1.453a2.026 2.026 0 00-1.49-.396c-.242.032-.487.13-.825.308a1.64 1.64 0 01-1.58-.008 1.615 1.615 0 01-.795-1.353c-.015-.38-.051-.64-.145-.863a2.007 2.007 0 00-1.09-1.083zM12.5 15c1.67 0 3.023-1.343 3.023-3S14.169 9 12.5 9c-1.67 0-3.023 1.343-3.023 3s1.354 3 3.023 3z'
						fill={fill ? fill : 'var(--light)'}
					/>
				</svg>
			);
		case 'phrase':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M3.464 20.535C4.93 22 7.286 22 12 22c4.714 0 7.071 0 8.535-1.465C22 19.072 22 16.714 22 12s0-7.071-1.465-8.536C19.072 2 16.714 2 12 2S4.929 2 3.464 3.464C2 4.93 2 7.286 2 12c0 4.714 0 7.071 1.464 8.535zM18.75 16a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h12a.75.75 0 01.75.75zM18 12.75a.75.75 0 000-1.5H6a.75.75 0 000 1.5h12zM18.75 8a.75.75 0 01-.75.75H6a.75.75 0 010-1.5h12a.75.75 0 01.75.75z'
						fill='#fff'
					/>
				</svg>
			);
		case 'key':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						fillRule='evenodd'
						clipRule='evenodd'
						d='M18.977 14.79a6.907 6.907 0 10-11.573-3.159c.095.369.01.768-.258 1.037L3.433 16.38a1.48 1.48 0 00-.424 1.21l.232 2.089c.025.223.125.43.283.589l.208.208a.987.987 0 00.589.283l2.089.232a1.48 1.48 0 001.21-.424l.71-.71-1.747-1.728a.75.75 0 111.055-1.066l1.752 1.733 1.942-1.942c.27-.27.668-.353 1.037-.258a6.904 6.904 0 006.608-1.806zm-6.391-6.204a2 2 0 112.828 2.828 2 2 0 01-2.828-2.828z'
						fill='#fff'
					/>
				</svg>
			);
		case 'basket':
			return (
				<svg
					width={24}
					height={24}
					fill='none'
					xmlns='http://www.w3.org/2000/svg'
					{...props}
				>
					<path
						d='M3 6.524c0-.395.327-.714.73-.714h4.788c.006-.842.098-1.995.932-2.793A3.68 3.68 0 0112 2a3.68 3.68 0 012.55 1.017c.834.798.926 1.951.932 2.793h4.788c.403 0 .73.32.73.714a.722.722 0 01-.73.714H3.73A.722.722 0 013 6.524zM11.607 22h.787c2.707 0 4.06 0 4.94-.863.881-.863.971-2.28 1.151-5.111l.26-4.08c.098-1.537.146-2.306-.295-2.792-.442-.487-1.188-.487-2.679-.487H8.23c-1.491 0-2.237 0-2.679.487-.441.486-.392 1.255-.295 2.791l.26 4.08c.18 2.833.27 4.249 1.15 5.112C7.545 22 8.9 22 11.607 22z'
						fill='#98A1C0'
					/>
				</svg>
			);
		default:
			break;
	}
}
